import { cn } from "@/lib/utils";
import { Image } from "@umahealth/occipital";
import Doctor from "@/assets/doctorLogin.png";
import { ReactNode } from "react";

export function LoginBanner() {
  return (
    <div className="hidden md:block w-[50vw] h-full bg-primary relative">
      <Image
        className="absolute left-1/2 transform -translate-x-1/2 bottom-0 text-center object-cover h-[80vh] md:h-[80vh]"
        src={Doctor}
        priority={false}
        alt=""
      />
      <div className="absolute left-0 top-0 text-center w-full md:w-[50vw] flex flex-col justify-center z-20">
        <h1 className="text-white text-lg text-center font-semibold mt-12">
          Te damos la bienvenida
        </h1>
        <h1 className="text-white text-lg text-center font-semibold">
          A tu portal de salud
        </h1>
      </div>
    </div>
  );
}

export function LoginContainer({
  className,
  children,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) {
  return (
    <div
      className={cn(
        "m-0 h-screen relative flex flex-col md:flex-row content-center w-full overflow-y-auto",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}
export function LoginActionArea({
  className,
  children,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) {
  return (
    <div
      className={cn(
        "bg-white p-8 h-full flex flex-col md:w-[50vw] items-center content-center justify-center mx-auto max-w-xl",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}

export function LoginPage({children, className} : {children?: ReactNode, className?: string}){
  return <LoginContainer>
    <LoginBanner/>
    <LoginActionArea className={className}>
      {children}
    </LoginActionArea>
  </LoginContainer>
}