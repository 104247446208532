'use-client'
import { initializeApp } from 'firebase/app'
import { getAuth, FacebookAuthProvider, GoogleAuthProvider } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
	apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
	authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
	projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
	storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
	appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID
}

export const firebaseInitializeApp = initializeApp(firebaseConfig)

export const facebookProvider = new FacebookAuthProvider()
export const googleProvider = new GoogleAuthProvider()
export const firestore = getFirestore(firebaseInitializeApp)
export const storage = getStorage(firebaseInitializeApp)
export const auth = getAuth(firebaseInitializeApp)
export default firebaseInitializeApp
