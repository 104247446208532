'use client'
import React from 'react'
import { Icon, Input } from '@umahealth/occipital/client'
import { Button, Title } from '@umahealth/occipital'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { getRecoveryPassword } from '@/services/nextQuerys/getRecoveryPassword'
import { FacebookAuthProvider, getAuth, GoogleAuthProvider, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth'
import { auth } from "@/config/firebase";
import { useRouter } from 'next/navigation'
import { saveUserInServer } from '@/auth/authServerActions'
import Google from "@/assets/google.png";
import Facebook from "@/assets/facebook.png";
import { ImageButton } from '@/storybook/components/shared'
import Link from 'next/link'

interface ICheckUserForm {
	email: string
}

interface IFormLoginEmail {
  email: string;
  password: string;
}

export function useSignInWithEmailAndPassword() {
  return useMutation({
		mutationKey: ['signInWithEmailAndPassword'],
		mutationFn: async ({ email, password }: IFormLoginEmail) => {
			return await signInWithEmailAndPassword(auth, email, password);
    },
  }
  );
}

function RecoveryElement({email, type} : {email : string, type: 'google.com' | 'facebook.com' | 'password'}){

  const router = useRouter()

    async function handleLogin(name: "Google" | "Facebook") {
    const provider = {
      Google: new GoogleAuthProvider(),
      Facebook: new FacebookAuthProvider(),
    } as const;
      const credential = await signInWithPopup(auth, provider[name]);

      const idTokenResult = await credential.user.getIdTokenResult();

      if (idTokenResult.token) {
        await saveUserInServer(idTokenResult);
      }
      router.push("/home");
  }

    if (type === 'facebook.com'){
          return <>
            <Title className='mb-4' hierarchy='h1' size='text-m' weight='font-semibold' > Tienes una cuenta asociada con el email: {email}</Title>
            <ImageButton
            className="mb-4"
            action={() => {
              handleLogin("Facebook");
            }}
            name="Facebook"
            logo={Facebook}
          />
		</>
    }

    if (type === 'google.com'){
          return <>
            <Title className='mb-4' hierarchy='h1' size='text-m' weight='font-semibold' > Tienes una cuenta asociada con el email: {email}</Title>
            <ImageButton
            className="mb-4"
            action={() => {
              handleLogin("Google");
            }}
            name="Google"
            logo={Google}
          />
		</>
    }

    if (type === 'password'){
        return <Title hierarchy='h1' size='text-l'> Enviamos un mail de recuperación de contraseña, fijate en tu casilla de entrada!</Title>
    }
}

export function ForgotPassword(){

	const {
		register,
		handleSubmit,
		setError,
		formState: {errors}
	} = useForm<ICheckUserForm>()

  const auth = getAuth()

	const recoveryPasswordMutation = useMutation({
		mutationKey: ['recoveryPassword'],
		mutationFn: getRecoveryPassword
	})

  const sendPasswordResetEmailMutation = useMutation({
    mutationKey: ["sendPasswordResetEmail"],
    mutationFn: (email : string) => sendPasswordResetEmail(auth, email),
  });

	const onSubmit : SubmitHandler<ICheckUserForm> = async (form) => {
		recoveryPasswordMutation.mutate(form, {
      onSuccess: (() => {
        sendPasswordResetEmailMutation.mutate(form.email);
      })
    })

    if (sendPasswordResetEmailMutation.isError){
      return setError("email", {
        type: "custom",
        message: "No se pudo enviar el mail de recuperación de contraseña.",
      });
    }

    if (!recoveryPasswordMutation?.data?.exists) {
      return setError("email", {
        type: "custom",
        message:
          "No encontramos un usuario asociado a este email, corriga los datos y vuelva a intentarlo.",
      });
    }

    if (
      !recoveryPasswordMutation?.data?.details ||
      recoveryPasswordMutation?.data?.details?.length === 0
    ) {
      return setError("email", {
        type: "custom",
        message:
          "Ocurrió un error al obtener la información asociada a este email.",
      });
    }
	}

	if (!!recoveryPasswordMutation.data?.details && recoveryPasswordMutation.data?.details?.length > 0){
        return <>
        {recoveryPasswordMutation.data?.details.map( recoveryUser => {
            return <RecoveryElement type={recoveryUser.providerId} email={recoveryUser.email} key={recoveryUser.uid}/>
        })}
        </>
	}

    return <>
      <Link className='self-start flex items-center text-primary' href='/forgot'>
        <Icon className='mr-2' name='chevronLeft'/>
        Volver
      </Link>
			<Title className='self-start' hierarchy='h1' size='text-l' weight='font-semibold' > Recuperar Contraseña</Title>
			<form className='w-full' onSubmit={handleSubmit(onSubmit)}>
                <Input classNameContainer='mb-4'  autoComplete="email" id='email' inputMode='email' type='email' label='Ingresa tu email' placeholder='ejemplo@hotmail.com' {...register('email')} error={errors.email?.message}/>
                <Button type='submit' loading={recoveryPasswordMutation.isPending}> Continuar </Button>
      </form>
		</>
}