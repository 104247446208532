"use client";

import { email_exists } from "@/config/endpoints";


interface IDetail {
   uid: string
    email: string
    providerId: 'password' | 'google.com' | 'facebook.com'
}

interface IRecoveryPassword {
  uid: string | null;
  verified: boolean;
  details: {
    [key: number]: IDetail;
  } | null;
  exists: boolean;
}

export async function getRecoveryPassword({
  email,
}: {
  email: string;
}) {

  const response = await fetch(`${email_exists}?email=${email}`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "GET",
  });

  if (!response.ok) {
    console.error(
      `error en endpoint a: ${email_exists}, status: ${response.status}, error: ${response.statusText}`
    );
    return null;
  }

  const data = (await response.json()) as IRecoveryPassword; 
  
  if (!data.exists) {
    console.error(
      `${email_exists}, user no existe`
    );
    return null;
  }
 
  let detailArray: IDetail[] = []
  if (data.details){
  detailArray = Object.values(data.details);
  }

  return {
    ...data,
    details: detailArray
  };

  // mutate data
  // revalidate cache
}
