import { Button } from '@umahealth/occipital'
import { StaticImageData } from 'next/image'
import { Image } from '@umahealth/occipital';


interface IImageButton {
	name: 'Google' | 'Facebook' | 'Microsoft';
	logo: StaticImageData;
	action?: React.MouseEventHandler<Element> 
	className?: string
	children?: string
}

export function ImageButton({ action, name, logo, className, children = `Ingresar con ${name}`} : IImageButton){
	return (
		<Button className={`${className} p-2`} variant='outlined' type='button' size='full' onClick={action} name={name}>
			<div className='flex'>
				<Image className='w-auto h-5' src={logo} alt='' width='18px' height='18px'/>
				<span className='block w-2'/>
				{children}
			</div>
		</Button>
	)
}